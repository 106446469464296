import { styled, withStyle } from 'baseui'
import {
  StyledBodyCell as BaseStyledCell,
  StyledHeadCell as BaseStyledHeadCell,
} from 'baseui/table-grid'

export const ImageWrapper = styled('div', ({ $theme }) => ({
  width: '40px',
  height: '40px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '20px',
  backgroundColor: $theme.colors.backgroundF7,
}))

export const Icon = styled('span', () => ({
  width: '100%',
  height: 'auto',
}))

export const TableWrapper = styled('div', () => ({
  width: '100%',
  // height: "80vh",

  // overflowX: "scroll",
  // overflow: "hidden",
}))

export const PointDetailTableWrapper = styled('div', () => ({
  width: '100%',
}))

export const StyledTable = styled('table', ($theme) => ({
  border: '1px solid #e0e0e0',
  alignContent: 'start',
  width: '100%',
  borderSpacing: '0px',
  position: 'relative',
  boxSizing: 'border-box',
}))

export const StyledHead = styled('thead', () => ({
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
}))

export const StyledTBodyCate = styled('tbody', () => ({
  maxHeight: 'calc(100vh - 190px)',
}))

export const StyledTBody = styled('tbody', () => ({
  maxHeight: 'calc(100vh - 190px - 25px)',
}))

export const StyledTBodySale = styled('tbody', () => ({
  maxHeight: 'calc(100vh - 200px)',
}))

export const StyledTBodyDrawer = styled('tbody', () => ({
  maxHeight: 'calc(100vh - 180px)',
}))

export const StyledTBodyDrawerAction = styled('tbody', () => ({
  maxHeight: 'calc(100vh - 210px)',
}))

export const StyledTBodyDrawer1 = styled('tbody', () => ({
  maxHeight: '55vh',
  '@media only screen and (max-width: 1800px)': {
    maxHeight: '50vh',
  },
  '@media only screen and (max-width: 1700px)': {
    maxHeight: '45vh',
  },
  '@media only screen and (max-width: 1300px)': {
    maxHeight: '35vh',
  },
  '@media only screen and (max-width: 1100px)': {
    maxHeight: '24vh',
  },
}))
export const StyledTBodyMail = styled('tbody', () => ({
  maxHeight: '50vh',
  '@media only screen and (max-width: 1800px)': {
    maxHeight: '50vh',
  },
  '@media only screen and (max-width: 1700px)': {
    maxHeight: '50vh',
  },
  '@media only screen and (max-width: 1300px)': {
    maxHeight: '50vh',
  },
  '@media only screen and (max-width: 1100px)': {
    maxHeight: '50vh',
  },
}))

export const StyledHeadCell = styled('th', () => ({
  fontFamily: "'Lato', sans-serif",
  fontWeight: 700,
  borderRight: '1px solid rgb(210, 210, 210)',
  color: '#161F6A !important',
  padding: '12px',
  textAlign: 'left',
  position: 'flexed',
}))

export const StyledCell = styled('td', () => ({
  fontFamily: "'Lato', sans-serif",
  fontWeight: 400,
  color: '#161F6A !important',
  alignSelf: 'center',
  alignContent: 'center',
  wordWrap: 'break-word',
  padding: '10px',
  borderBottom: '0.3px solid rgba(224, 224, 224, 0.6)',
  borderTop: '0.3px solid rgba(224, 224, 224, 0.6)',
  // display: "flex",
  // flexDirection: "column",
  // justifyContent: "center",
  // height: "100%",
  // ":hover": {
  //   backgroundColor: "#EDEDED",
  // },
}))

export const StyledRow = styled('tr', () => ({
  width: '100%',
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
  },
}))

export const StyledHeadCellCenter = withStyle(BaseStyledHeadCell, () => ({
  fontFamily: "'Lato', sans-serif",
  fontWeight: 700,
  color: '#161F6A !important',
  alignItems: 'center',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  borderColor: 'rgba(0, 0, 0, 0.12)',
  alignSelf: 'start',
  justifyContent: 'center',
}))

export const StyledCellCenter = withStyle(BaseStyledCell, () => ({
  fontFamily: "'Lato', sans-serif",
  fontWeight: 400,
  color: '#161F6A !important',
  alignSelf: 'center',
  justifyContent: 'center',
}))
