import { ApolloProvider } from "@apollo/react-hooks";
// import 'promise-polyfill/src/polyfill';
import "@redq/reuse-modal";
import ApolloClient from "apollo-boost";
import { BaseProvider } from "baseui";
import "core-js/features/string/repeat";
import "isomorphic-unfetch";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import openNotification, {
  typeNotificaton,
} from "./components/openNotification/openNotification";
import store from "./redux/store";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import { theme } from "./theme";
import "./theme/global.css";

const client = new ApolloClient({
  fetchOptions: { fetch },
  uri: process.env.REACT_APP_API_URL,
  request: (operation) => {
    const token = localStorage.getItem("access_token");
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  },
  onError: (props) => {
    if (
      props.graphQLErrors &&
      props.graphQLErrors?.[0].message === "Unauthorized"
    ) {
      setTimeout(() => {
        window.location.replace("/");
      }, 2000);
      localStorage.removeItem("access_token");
      openNotification(
        typeNotificaton.error,
        `ログイン期限が切られました。\n もう一度ログインしてください。`
      );
    }
  },
});

if (process.env.REACT_APP_ENVIRONMENT?.length === 0) {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
} // build production prevent console.log

function App() {
  const engine = new Styletron();
  return (
    <ApolloProvider client={client as any}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <HashRouter>
            <Routes />
          </HashRouter>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
