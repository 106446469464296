// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = "/";
export const PRODUCTS = "/products";
export const GIFTS = "/gifts";
export const PRICE_SETTINGS = "/pricesettings";
export const CATEGORY = "/category";
export const MAKER = "/maker";
export const HANBAITEN_MAIL = "/hanbaiten-mail";
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const ORDERS = "/orders";
export const CUSTOMERS = "/customers";
export const COUPONS = "/coupons";
export const SETTINGS = "/settings";
export const STAFF_MEMBERS = "/staff-members";
export const SITE_SETTINGS = "/site-settings";
export const POINT = "/point";
export const POINT_ACTION = "/activity";
export const POINT_EXCHANGE = "/point-exchange";
export const CAMPAIGN = "/campaign";
export const TAG_SETTINGS = "/tag-settings";
export const SALE = "/sale";
export const INTRO_PRODUCT = "/introduce-product";
export const IMPORT_PDF = "/import-pdf";
export const VR_LINK = "/vr-link";
export const MAKER_LINK = "/maker-link";
export const AUTHORIZER = "/authorizer";
export const FLYER = "/flyer";
export const SEND_MAIL_SETTING = "/sendmailsetting";
export const VISITOR_SETTING = "/visitor-setting";

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = "¥";
