import { useApolloClient, useMutation } from "@apollo/react-hooks";
import jwt from "jsonwebtoken";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import openNotification, {
  typeNotificaton,
} from "../components/openNotification/openNotification";
import { LOGIN } from "../graphql/mutation/login.mutation";
import { setUserLoginId } from "../redux/common";

type AuthProps = {
  isAuthenticated: boolean;
  role: string;
  authenticate: Function;
  signout: Function;
  makeAuthenticated: Function;
  // dataLogin: any
};

export const AuthContext = React.createContext({} as AuthProps);
export const Role = {
  admin: "admin",
  hanbaiten: "hanbaiten",
};

const ValidToken = () => {
  const token = localStorage.getItem("access_token");
  // JWT decode & check token validity & expiration.
  if (token) {
    let auth = jwt.decode(token);
    let timenow = new Date().getTime();
    if (timenow < auth["exp"] * 1000) {
      return { isValid: true, role: auth["role"] };
    }
  }
  return {
    isValid: false,
    role: null,
  };
};

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = useState(ValidToken().isValid);
  const [role, setRole] = useState<any>(ValidToken().role);

  const dispatch = useDispatch();

  const client = useApolloClient();

  // if (isAuthenticated){ const { data: dataLogin } = useQuery(GET_PROFILE);}

  // const [dataLogin, setDataLogin] = useState()

  const [login] = useMutation(LOGIN, {
    onError: (error) => {
      openNotification(
        typeNotificaton.error,
        `${error.toString().split(" ").slice(2).join("")}`
      );
    },
    onCompleted: (data) => {
      if (
        data.login.userType !== "admin" &&
        data.login.userType !== "hanbaiten"
      ) {
        openNotification(
          typeNotificaton.error,
          "無効なユーザー名またはパスワード"
        );
        return;
      }
      client.cache.reset();
      // makeAuthenticated(true)
      if (data.login.userType === "admin") setRole(Role.admin);
      else setRole(Role.hanbaiten);
      localStorage.setItem("access_token", data.login.access_token);
      // document.cookie = `access_token=${data.login.access_token},domain:.web-mirai.jp`
      let iExpiresTime = parseInt(data.login.expiresTime);
      let timenow = new Date().getTime();
      makeAuthenticated(true);
      setTimeout(() => {
        localStorage.removeItem("access_token");
        makeAuthenticated(false);
      }, iExpiresTime - timenow);
    },
  });

  function authenticate({ username, password }, cb) {
    login({ variables: { username, password } })
      .then((data) => {
        localStorage.setItem("userLoginId", data?.data.login.id);
        localStorage.setItem("userNameLogin", data?.data.login.username);
        const userLoginId = localStorage.getItem("userLoginId");
        dispatch(setUserLoginId(userLoginId));
        cb("無効なユーザー名またはパスワード");
      })
      .catch(({ err }) => {
        cb(err);
        openNotification(
          typeNotificaton.error,
          "無効なユーザー名またはパスワード"
        );
        return <Redirect to="/login" />;
      });
  }

  function signout(cb) {
    makeAuthenticated(false);
    setRole(null);
    localStorage.removeItem("access_token");
    localStorage.removeItem("userLoginId");
    setTimeout(cb, 100);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        makeAuthenticated,
        signout,
        role,
        // dataLogin,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
